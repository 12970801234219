import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import styles from '../../../styles/main.module.css'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import SideNews from '../../generalComponents/SideNews'
import LineWithDot from '../../generalComponents/LineWithDot'
import Promotion from '../../generalComponents/Promotion'
import { useNavigate } from 'react-router'

function NewsAndAnalysis(props: any) {
  const {newsAndAnalysisFrontPage} = props;
  let firstArticle : any = null;
  let secondArticle : any = null;
  let thirdArticle : any = null;
  const navigate = useNavigate();
  const goToArticle = (article: any) => {
      const state = {id: article.id};
      navigate("/article/"+ article.url_key, {state: state});
  };
  if (newsAndAnalysisFrontPage && newsAndAnalysisFrontPage.length) {
     for (var i = 0; i < newsAndAnalysisFrontPage.length; i++) {
        if (newsAndAnalysisFrontPage[i].first_page_first_news) {
          firstArticle = newsAndAnalysisFrontPage[i];
        } else if (newsAndAnalysisFrontPage[i].first_page_first_news2) {
          secondArticle = newsAndAnalysisFrontPage[i];
        } else if (newsAndAnalysisFrontPage[i].first_page_first_news3) {
          thirdArticle = newsAndAnalysisFrontPage[i];
        }
     }
  } 
  let backgroundImageLink = {backgroundImage: `url(null)`};
  if (firstArticle) {
    backgroundImageLink = {backgroundImage: `url(${firstArticle.imagePath})`};
  }
  return (
    <div className="d-flex flex-column gap-4">
      <Promotion />
      <div className="d-flex align-items-center">
        <div className={styles.homePageBorders}></div>
        <div className={`${styles.homePageNewAnaly} fs-lg-2 fs-1`}>News&Analysis</div>
        <div className={styles.homePageBorders}></div>
      </div>
      <Container className='d-flex flex-column w-100 px-0 py-2'>
        <Row>
          <Col className='col-12 col-lg-6'>
            <div className={styles.homePageNewsBackground} style={backgroundImageLink}>
            </div>
            <div >
              <div className={styles.homePageNewsTitle} onClick={() => {goToArticle(firstArticle)}} style={{cursor: 'pointer'}}>
                {firstArticle?.title}
              </div>
              <div className={styles.homePageNewsDetails} dangerouslySetInnerHTML={{__html: firstArticle?.preview ? firstArticle?.preview + '...' : ''}}></div>
            </div>
            <div style={{ transform: "translateY(-50%)" }}>
              <LineWithDot
                customstyle={{ width: "100px" }}
              />
            </div>
          </Col>

          <Col className="d-flex flex-column justify-content-between pt-3">
            {
                secondArticle && <div style={{cursor: 'pointer'}} onClick={() => {goToArticle(secondArticle)}}><SideNews article={secondArticle} /></div>
            }
            {
                thirdArticle && <div style={{cursor: 'pointer'}} onClick={() => {goToArticle(thirdArticle)}}><SideNews article={thirdArticle} /></div>
            }
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NewsAndAnalysis