import { useEffect, useState } from "react";
import styles from '../../styles/main.module.css';
import date from '../../images/datedark.png';
import Socials from '../generalComponents/Socials';
import facebook from '../../images/facebookW.png';
import insta from '../../images/insta.png';
import gmail from '../../images/gmail.png';
import whatsapp from '../../images/whatsapp-wh.png';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import AuthorsListedArticles from './AuthorsListedArticles';
import linkedin from '../../images/linkedin-wh.png'
import email from '../../images/email-wh.png'
import Comment from './Comment';
import Comments from './Comments';




function InsideArticle(props: any) {
    const {article, moreArticles, hasMoreArticles, loadMore} = props;
    const [commentsLastUpdated, setCommentsLastUpdated] = useState(+(new Date().getTime()));
    const backgroundImageLink = {backgroundImage: `url(${article.imagePath})`};
    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://www.oratlas.com/LOT/artefacto.js";
        script.async = true;
        script.charset = "UTF-8";
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        }
    }, []);
    
    useEffect(() => {
        // Function to apply CSS to hide the div containing the link inside OratlasWidget
        const applyCSSHide = () => {
            const styleTag = document.createElement('style');
            styleTag.innerHTML = `
                #OratlasWidget div[id^="OO"] {
                    display: none !important; /* Hide the div containing the link */
                }
            `;
            document.head.appendChild(styleTag);
        };

        applyCSSHide(); // Call the function when component mounts

    }, []);

    return (
        
        <div className={styles.insideArticle}>
            <div dangerouslySetInnerHTML={{ __html: '<!-- oratlas aaa -->' }}></div>
            <div className={styles.insideArticleImage} style={backgroundImageLink}>
            </div>
            <div className="d-flex flex-column flex-lg-row me-lg-4 me-0">
                <div className={styles.insideArticleInfo}>
                    <div className={styles.insideAuthorStyle}>
                        {article.author.description}
                    </div>
                    <div style={{display:'flex', justifyContent: "space-between", alignItems:"baseline"}}>
                        <div className={styles.insideArticleDate}>
                            <img src={date} className={styles.dateLogo}></img>
                            {article.releaseDate}
                        </div>
                        <div style={{display:'flex', alignItems:"center"}}>
                            <div className={styles.AudioDisplaytext}><text>Ακρόαση</text></div>
                            <div style={{transform: "scale(0.7)"}} id="OratlasWidget" data-idioma="el"></div>
                        </div>
                        
                    </div>
                
                    <div className={styles.insideArticleTitle} >
                        {article.title}
                    </div>
                    <div className={styles.insideArticleDetails} dangerouslySetInnerHTML={{__html: article.body}}></div>
                    <div dangerouslySetInnerHTML={{ __html: '<!-- oratlas zzz -->' }}></div>
                </div>
                <div className={`${styles.insideArticleSocials} d-flex flex-row flex-lg-column`}>
                    <Socials type="facebook" image={facebook} article={article} />
                    <Socials type="whatsapp" image={whatsapp} article={article} />
                    <Socials type="email" image={email} article={article} />
                    <Socials type="linkedin" image={linkedin} article={article} />
                    <Socials type="twitter" image={email} article={article} />
                </div>
            </div>
            <div className={`${styles.gapSocial} me-lg-4 mt-10`}>
                <div className="d-flex align-items-center">
                    <div className={styles.homePageBorders}></div>
                    <div className={`${styles.homePageNewAnaly} fs-lg-2 fs-1`}>Σχόλια</div>
                    <div className={styles.homePageBorders}></div>
                </div>
                <Comments articleId={article.id} key={commentsLastUpdated}/>
                <Comment articleId={article.id} setCommentsLastUpdated={setCommentsLastUpdated} />
                <div className="d-flex align-items-center">
                    <div className={styles.homePageBorders}></div>
                    <div className={`${styles.homePageNewAnaly} fs-lg-2 fs-1`}>Περισσότερα</div>
                    <div className={styles.homePageBorders}></div>
                </div>
                <AuthorsListedArticles articles={moreArticles} />
                <div className={styles.moreButtonContainer}>
                    <button className={styles.loadMoreButton} onClick={loadMore} disabled={hasMoreArticles ? false : true}>Περισσότερα...</button>
                </div>
            </div>
        </div>
    )
}

export default InsideArticle