import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import SidebarMenu from './SidebarMenu';
// import SideBarMenu from './SidebarMenu';
import Advert from './Advert';
import { Link } from 'react-router-dom';

function SideBar(props: any) {

  return (
    <Container className='d-flex flex-column gap-lg-5 gap-1 sideBarMenuMob'>
      <Row className='mx-0'>
        <SidebarMenu {...props} />
      </Row>
      
        <Row className='w-100'>
          <Advert/>
        </Row>

    </Container>
  )
}

export default SideBar